html, body {
    padding: 0;
    margin: 0;
}

body.overflow-h {
    
    @media (max-width: 768px) {
        overflow: hidden !important;
    }
}

.WidgetTitle__Header-sc-ruy1gu-2 {
    display: none;
}